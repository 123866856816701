.App {
  padding-right: 20px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 0em;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}

.tabs {
  display : flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  position: relative;
  background-color: #fff;
}

.tab {
  border: 1px solid #aaa;
  background-color: #888;
  padding: 8px;
  font-size: 0.5em;
  margin-right: 4px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tab a {
  color: #ddd;
}

.tab.active {
  background-color: #bbb;
}

.container {
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
}

.containerHeader {
  padding: 20px 30px 16px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerHeader strong {
  font-size: 0.475em;
  color :#000;
}

.containerContent {
  padding: 20px 30px;
  border-top: 1px solid #ddd;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionButton {
  color : #fff;
  font-size: 0.5em;
  text-decoration: none;
  background-color: cornflowerblue;
  padding: 6px 10px;
  border-radius: 4px;
}

.listTable th {
  font-size: 0.5em;
  text-align: left;
  padding: 0.5em;
}

.listTable td {
  font-size: 0.5em;
  text-align: left;
  padding: 0.5em;
}

.listTable tr:nth-child(even) {
  background-color: #888;
}

.listTable tr td, .listTable tr th {
  white-space: nowrap;
}

.listTable tr td  a {
  text-decoration: none;
}

.listTable tr:nth-child(odd) a {
  color: #fff;
}
.listTable tr:nth-child(even) a {
  color: #fff;
}

.listRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3em;
}

.listActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.listActions a {
  padding : 6px 10px;
  border-radius: 4px;
  background-color: #eee;
  color: #111;
  text-decoration: none;
}

.listActions a:nth-child(2) {
  margin-left: 1em;
}

.listActions a.red {
  background-color: orangered;
  color: white;
}
.listCell {
  color: #000;
  font-size: 0.5em;
}

.listRow:last-child {
  margin-bottom: 0;
}

.lc-33 {
  width: 33%;
}

.lc-20 {
  width: 20%;
}

.lc-25 {
  width: 25%;
}

.listHead {
  font-size: 0.4em;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3em;
  font-size: calc(10px + 2vmin);
  color: white;
  flex : 1;
  position: absolute;
  left: 0;
  top : 0;
  width: 100%;
  height: 100%;
  
  /*background-color: rgba(0, 0, 0, 0.4)*/
}

.modalContent {
  padding: 1em;
  background-color: rgba(58,58,58,1);
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  border: 1px solid #000;
}

.modalContent label {
  display: flex;
  flex-direction: column;
  font-size: 0.5em;
}
.modalContent p {
  font-size: 0.5em;
}

.modalContent input, .modalContent select {
  font-size: 1em;
  padding: 0.4em;
  border-radius: 3px;
  border: 0px;
  margin: 5px 0;
  width: calc(100% - 0.8em);
}

label.splitted input, label.splitted select {
  width: calc(50% - 0.8em);
}

.modalContent .modalButtons {
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
}

.modalContent .modalButtons a {
  border: 1px solid #888;
  color : #fff;
  padding: 0.3em;
  background-color: #aaa;
  margin-left: 0.5em;
  font-size: 0.6em;
  text-decoration: none;
  border-radius: 3px;
}

.modalContent .modalButtons a[disabled] {
  background-color: #888;
}

.spam_score {
  font-size: 1.2em;
}